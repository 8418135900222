
import React,{Component} from 'react';
import {Button, Image} from 'react-bootstrap';
import '../register/register.css'
import Header from "../header/header";
import {bindActionCreators} from "redux";
import * as userActions from "../../redux/user/user_actions";
import * as companyActions from "../../redux/company/company_actions";
import * as mainActions from "../../redux/main/main_actions";
import { connect } from 'react-redux';

class OnBoardingPage extends Component{

    onNext = () => {
        window.location.href = '/';
    };

    render(){
        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
        if(!checkLogin) {
            window.location.href = '/login';
        }
        return(
            <div className="full_w full_h onboarding-3">
                {/* <Header type={0} login={null}/> */}
                <div className='col-lg-5 welcom-page-image'>
                    <div className='image-trance-box'></div>
                        <Image src={require('../../assets/images/teemie-logo.png')} style={{width:"194px" , height:"53px",position:"absolute" , top:"74px" , left:"69px"}}/>
                </div> 
                <div  className='col-lg-7 welcome-content'>
                    <div className='Title'> ALMOST THERE</div>
                    <div className='para'> You are ready. Just a couple of helpful tips to begin, the main things to do first are to start adding your staff, splitting them into whatever teams you wish and building out your Welcome too so that they have that information when they first log in.
                    <div> Also, now that you have created your account as the company owner, your mobile app account will be activated and ready .</div>
                    <div>Hope you enjoy Teemie :)</div>
                   </div>
                <div><Button className="welcome-button shadow_popup" onClick={this.onNext}> NEXT </Button></div>
                </div>         
            </div>
        )
    }
}
export default connect(state => ({
        checkLogin : state.main.checkLogin
    }),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch),
        companyActions: bindActionCreators(companyActions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
    })
)(OnBoardingPage);
// export default OnBoardingPage;