/**
 * @copyright 2017 @ Tech Seed Labs
 * @author rocachien
 * @create 2017/08/12 10:37
 * @update 2017/08/12 10:37
 * @file src/components/menu-left/menu-left.js
 */
import React from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from "redux";
import {browserHistory} from 'react-router';
import { Image, Modal } from "react-bootstrap";
import * as messageActions from "../../redux/message/message_actions";
import * as teamActions from "../../redux/team/team_actions";
import Config from "../../config";

class MenuLeft extends React.PureComponent{

    constructor(props){
        super(props);

        const location = window.location;

        this.state = {
            route: (location.pathname && location.pathname.length > 3) ? location.pathname : '/my-team',
            infoMember:null,
            isRemoveMember: false,
        };
    }

    componentDidMount = () => {
        let {teamList} = this.props;
        if(!teamList){
            this.props.teamActions.getTeamList();
        }
    };

    onMenu=(data)=>{
        let {teamList} = this.props;
        if(!teamList) return;
        let arrUseTeam = ['MESSAGES','ROSTER','TASKS','CALENDAR'];
        if(arrUseTeam.indexOf(data.id) !== -1 && teamList && teamList.length === 0){
            // Config.popup.show('INFO',[Config.alertMess({code:'NOTTEAM'})]);
            this.setState({ isRemoveMember: true });
            return;
        }
        if(data.id !== 'MESSAGES' && this.props.messageRoom && this.props.messageRoom.id){
            console.log('====== onLeaveRoom ======',this.props.messageRoom.id);
            this.onLeaveRoom(()=>{
                this.props.messageActions.messageRoom(null);
            });
        }
        this.setState({route: data.route});
        localStorage.removeItem('getYear'); 
        browserHistory.push(data.route);
    };

    onLeaveRoom = (cb) => {
        if(!Config.socket || Config.socket === null) {
            return false;
        }

        Config.socket.emit('put',
            {
                url: '/api/message/leave',
                data: {room: this.props.messageRoom.id}
            },()=>{
                cb && cb();
            });
    };

    closeModal = () => {
        this.setState({ isRemoveMember: false });
      };
    
    render() {
        let dataMenu = [
            // {
            //     id:'MYACCOUNT',
            //     name:'My Account',
            //     route:'/my-account',
            //     icon:require('../../assets/images/side-menu/my-team-icon.png')
            // },
            {
                id:'MYTEAM',
                name:'My Teams',
                route:'/my-team',
                icon:require('../../assets/images/side-menu/my-team-icon.png')
            },
            {
                id:'MESSAGES',
                name:'Messages',
                route:'/messages',
                icon:require('../../assets/images/side-menu/message-icon.png')
            },
            {
                id:'ROSTER',
                name:'Roster',
                route:'/roster',
                icon:require('../../assets/images/side-menu/roster-icon.png')
            },
            {
                id:'TASKS',
                name:'Tasks',
                route: '/tasks',
                icon:require('../../assets/images/side-menu/task-icon.png')
            },
            {
                id:'CALENDAR',
                name:'Calendar',
                route:'/calendar',
                icon:require('../../assets/images/side-menu/calendar.png')
            },
            {
                id:'ANNOUNCEMENTS',
                name:'Announcements',
                route:'/announcements',
                icon:require('../../assets/images/side-menu/announcement-icon.png')
            },
            {
                id:'WELCOME',
                name:'Welcome',
                route:'/welcome-manager',
                icon:require('../../assets/images/side-menu/welcome-icon.png')
            },           
        ];

        let checkLogin = localStorage.getItem('LOGIN');
        let AdminLogin = false
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
        if(this.props.teamList){
            this.props.teamList.map((dt)=>{
                if(dt.members && dt.members.admins){
                    dt.members.admins.map((_dt)=>{
                        console.log("=============>",_dt.userID)
                        if(checkLogin.user.id === _dt.userID){
                            AdminLogin = true
                        }
                    })
                }
            })
        }
        if(this.state.route!=location.pathname){
            this.setState({route:location.pathname})
        }
        let showWelcome = checkLogin && checkLogin.company && checkLogin.user && checkLogin.company.owner === checkLogin.user.id || AdminLogin
        console.log(this.state.checkLogin)
        console.log("AdminLogin",AdminLogin)
        let {teamList} = this.props;

        return(
            <nav class="navbar navbar-expand-lg navbar-light bg-white">
                        <div class="container-fluid w-100 flex-column p-0">
                          <a class="navbar-brand" href="#">MENU</a>
                          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                          </button>
                          <div class="collapse navbar-collapse" id="navbarSupportedContent">
                          
                            <ul class="navbar-nav mb-2 mb-lg-0 d-flex flex-column w-100">

                            {dataMenu.map((key, idx)=>{
                    if(key.id === 'WELCOME' && !showWelcome) return null;

                    let disable = false
                    if (teamList?.length === 0 && 
                        (key.id === "MESSAGES" || key.id === "ROSTER" || key.id === "TASKS" || key.id === "CALENDAR")) {
                        disable = true;
                    }

                    return(
                        <li className='nav-item' onClick={()=>this.onMenu(key)}>
                            <label className={(this.state.route === key.route) ? 'nav-link active' : 'nav-link'} 
                            style={{opacity:disable ? "0.5" : "1.5"}}
                            >
                            {key.name}
                            </label>
                        </li>
                    )
                })}
                
                              
                              
                            </ul>                       
                          </div>
                        </div>
                      </nav>
        );
    }
}

export default connect(state => ({
        messageRoom: state.message.messageRoom,
        teamList: state.team.teamList,
        teamId: state.team.teamId
    }),
    (dispatch) => ({
        messageActions: bindActionCreators(messageActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
    })
)(MenuLeft);