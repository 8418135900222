import React,{Component} from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
// import * as userActions from "../../redux/user/user_actions";
// import * as companyActions from "../../redux/company/company_actions";
// import * as mainActions from "../../redux/main/main_actions";

import * as teamActions from "../../redux/team/team_actions";
import * as rosterActions from "../../redux/roster/roster_actions";
import * as memberActions from "../../redux/member/member_actions";
import '../register/register.css';
import './staff-hours.css';
import {browserHistory} from 'react-router';
import {Image, OverlayTrigger, Tooltip} from 'react-bootstrap';
import Config from "../../config";
import SearchBar from "../my-team-detail/search-bar";
import moment from "moment";
import ReactLoading from "react-loading";
import PrintButton from "../roster/PrintButton";

import Header from '../header/header';
import Loaderview from '../roster/loaderview';

class StaffHours extends Component {

    constructor(props) {
        super(props);
        this.state = {
            idTeam: null,
            memberList:null,
            rosterList:[],
            isLoadingExport: false,
            fixedValue:100,
            toggleTeamSelection:true,
           isTeamOpened:false,
        };
        this.dataFilter = {
            limit: 1000,
            skip: 0,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillMount() {
        // window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let scrollTop = window.pageYOffset;
        if(scrollTop > 5) {
            this.setState({
                fixedValue: 60
            });
        } else {
            this.setState({
                fixedValue: 70
            });
        }
    }

    init = () => {
        let dataFilter = this.dataFilter;
        dataFilter.team = this.state.idTeam;
        dataFilter.timeFrom = moment().startOf('day').toISOString();
        dataFilter.timeTo = moment().endOf('year').toISOString();
        // return;
        this.props.rosterActions.getListRoster(dataFilter,(err, data)=> {
            if(err){
                // Config.popup.show('INFO',err.message);
                this.setState({
                    rosterList:[]
                });
            }
            else{
                this.setState({
                    rosterList:data
                });
            }
        });
    };

    _goToDeTail(idx) {
        browserHistory.push({
            pathname: '/staff-info',
            state: {id:idx, staff: true}
        });
    }

    _goToTarget = (slug) => {
        browserHistory.push(slug);
    };

    changeDropDownTeam = (data) => {
        this.setState({
            idTeam: data.id
        },()=>{
            this.init();
            this.setState({
                toggleTeamSelection:false
            });
        });
    };

    _getMemberTeamList = () => {
        this.props.teamActions.getMemberTeamList({team: this.state.idTeam},(err, data)=> {
            if(err){
                // Config.popup.show('INFO',err.message);
                this.setState({
                    memberList: []
                })
            }
            else{
                // that._goScreenTeamDetai();
                this.setState({
                    memberList: data
                })
            }
        });
    };

    setDataListTeam = (data,id) => {
        this.setState({
            idTeam: this.state.idTeam ? this.state.idTeam : (id ? id : null)
        },()=>{
            this.init();
            this._getMemberTeamList();
            // this.getShift();
        });
    };

    changeTimeToSecond = (time) => {
        let a = time.toString().split('.');
        let second = Math.round(parseFloat('0.' + (a[1] || 0) )* 60);
        second = second <= 9 ? '0'+ parseInt(second,10) : parseInt(second,10);
        a = a[0] + ':' + second;
        return a;
    };

    _onLogout = () => {
        this.props.mainActions.checkOut((err, data) => {
            window.location.reload();
        });
    };

    _goToRoster = () => {
        browserHistory.push('/roster');
    };

    toggleTeamSelectionClick = () => {
        console.log("toggleTeamSelection",this.state.toggleTeamSelection,this.state.isTeamOpened);
        if(this.state.isTeamOpened){
            this.setState({
                toggleTeamSelection: false,
                isTeamOpened: false
            });
        }
        else{
            this.setState({
                toggleTeamSelection: true
            });
    }
}
    isTeamOpened = (value) => {
        console.log("isTeamOpened", value);
        this.setState({
            isTeamOpened: value
        })
    }
    _staffNameLength = (staffname)=>{
        console.log("-------userTitle",staffname)
        let maxLength = 15
        if (staffname.length > maxLength) {
            return staffname.substring(0, maxLength) + "...";
        } else {
            return staffname;
        }

    }
    render() {
        let {teamList} = this.props;
        let {memberList} = this.state;
        let myTeam = [];
        if(teamList && this.state.idTeam){
            myTeam = teamList.find(i=>{return (i.id === this.state.idTeam)});
        }

        let resultFilter = [];

        if(this.state.rosterList && memberList){
            let arrTeam = this.state.rosterList.slice(0);
            arrTeam.forEach(function (hash) {
                return function (a) {
                    if (a.staff && a.staff.id && !hash[a.staff.id]) {
                        hash[a.staff.id] = {staff:a.staff,activities: []};
                        resultFilter.push(hash[a.staff.id]);
                    }
                    if(a.staff && a.staff.id) hash[a.staff.id].activities.push(a);
                };
            }(Object.create(null)));

            if(resultFilter.length < memberList.length){
                for(let i in memberList){
                    let arr = resultFilter.filter(j=>j.staff && memberList[i].user && j.staff.id === memberList[i].user.id);
                    if(arr.length === 0){
                        resultFilter.push({staff:memberList[i].user,activities: []});
                    }
                }
            }

            for(let i in resultFilter){
                resultFilter[i].activities = resultFilter[i].activities.sort((a, b) => {return moment(a.time).valueOf() - moment(b.time).valueOf()})
            }
        }

        let formatDate = {
            sameDay: '[Today]',
            nextDay: 'DD MMM',
            nextWeek: 'DD MMM',
            lastDay: 'DD MMM',
            lastWeek: 'DD MMM',
            sameElse: 'DD MMM'
        };

        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;

        let showCreareShift =  null;
        if(checkLogin.company && checkLogin.company.id && checkLogin.company.owner && checkLogin.user.id === checkLogin.company.owner && memberList && memberList.length > 0){
            showCreareShift = memberList.filter(i=>i.role === "OWNER" && checkLogin.user.id === i.user.id);
            showCreareShift = showCreareShift.length > 0;
        }

        let login = localStorage.getItem('LOGIN');
        login = login ? JSON.parse(login) : null;
        let toggleTeamSelection = this.state.toggleTeamSelection;
        return (
            <div className="full_w full_h_1" style={{position:'relative'}} onClick={this.toggleTeamSelectionClick}>
              
              {!!this.state.isLoadingExport && <Loaderview/>}
                <Header isSearch={true}
                                teamList={teamList}
                                myTeam={myTeam}
                                toggleTeamSelection={toggleTeamSelection}
                                dataListTeam={this.setDataListTeam}
                                changeDropDownTeam={this.changeDropDownTeam} type={1} login={login} onLogout={this._onLogout}
                                isTeamOpened={this.isTeamOpened}
                                />

                <div className="register calendar-view row1 my-team-detail my-team staff-hours full_h_1 height-100vh">
                    <div style={{position: 'relative', minHeight: '100%'}} className="full_w full_h_1">
                        <div className="col-sm-12 display_col padding-0 full_h_1" style={{position: 'relative'}}>
                            <div className="search-bar col-sm-12 display_row1 align-center sub-header-fixed" style={{top:this.state.fixedValue}}>
                            {/* <div className="align-center back-to-roster"
                                     onClick={() => this._goToTarget('/roster')}><img
                                    src={require('../../assets/images/roster/icon-back-def.png')} alt={""}/>Back to
                                    Roster
                                </div> */}

                                {/* <div className="search-view" style={{flexWrap: 'wrap', justifyContent: 'flex-end'}}>
                                    {showCreareShift && <div className="display_row align-center btn-new-event button-not-resp"
                                         onClick={() => this._goToTarget('/shift')}>
                                        <Image src={require('../../assets/images/roster/icon-create-shift.png')}
                                               className="icon-plus"/>
                                        Create Shift
                                    </div>
                                    }
                                    {showCreareShift &&
                                        <OverlayTrigger placement="bottom" trigger={["hover", "focus"]} overlay={<Tooltip id="edit">Create New Roster</Tooltip>} >
                                            <div className="display_row align-center btn-new-event button-resp"
                                                 onClick={() => this._goToTarget('/shift')}>
                                                <Image src={require('../../assets/images/roster/icon-create-shift.png')}
                                                       className="icon-plus"/>
                                            </div>
                                        </OverlayTrigger>
                                    }

                                    <div className="display_row align-center btn-new-event topmenu active button-not-resp">
                                        <Image src={require('../../assets/images/roster/icon-hours-1.png')}
                                               className="icon-plus"/>
                                        View Staff Hours
                                    </div>
                                    <OverlayTrigger placement="bottom" trigger={["hover", "focus"]} overlay={<Tooltip id="edit">View Staff Hours</Tooltip>} >
                                        <div className="display_row align-center btn-new-event button-resp">
                                            <Image src={require('../../assets/images/roster/icon-hours-1.png')}
                                                   className="icon-plus"/>
                                        </div>
                                    </OverlayTrigger>

                                    <div className="display_row align-center btn-new-event button-not-resp"
                                         style={{opacity:this.state.memberList ? 1 : 0.2, position:'relative'}}>
                                        <Image src={require('../../assets/images/roster/icon-export.png')}
                                               className="icon-plus"/>
                                        <PrintButton id={"staff-hours"}
                                                     onStartEnd={()=>{this.setState({isLoadingExport: !this.state.isLoadingExport})}}
                                                     headerClass={"txt-condensed-extra-bold title"}
                                                     colItem={2}
                                                     nameSave={"StaffHours"}
                                        />
                                        Export to PDF
                                    </div>
                                    <OverlayTrigger placement="bottom"
                                                    trigger={["hover", "focus"]}
                                                    overlay={<Tooltip id="edit">Export to PDF</Tooltip>} >
                                        <div className="display_row align-center btn-new-event button-resp"
                                             style={{opacity:this.state.memberList ? 1 : 0.2, position:'relative'}}>
                                            <Image src={require('../../assets/images/roster/icon-export.png')}
                                                   className="icon-plus"/>
                                            <PrintButton id={"staff-hours"}
                                                         onStartEnd={()=>{this.setState({isLoadingExport: !this.state.isLoadingExport})}}
                                                         headerClass={"txt-condensed-extra-bold title"}
                                                         colItem={2}
                                                         nameSave={"StaffHours"}
                                            />
                                        </div>
                                    </OverlayTrigger>
                                </div> */}
                            </div>
        <div className="member-view shift-form full_h_1">
           <div className="back-to-page"  onClick={() => { browserHistory.goBack() }} style={{margin:"-106px 28px 0px",width:200,height:50,backgroundColor:"white",position:"fixed"}}>
                           <img alt="" src={require('../../assets/images/roster/icon-back-def.png')} style={{width:"12px" , height:"20px" , position:"fixed" , marginLeft:"-5px", marginTop:"10px"}} />
                             <div className='back-text' style={{marginLeft:"25px",marginTop:"10px"}}>Back</div>
                           </div>
            <div className="col-sm-12 month-view align-around scroll" style={{marginTop:"30px"}}>
                <div className="txt-condensed-extra-bold title" id="title-staff-hours" style={{fontSize: 24,lineHeight: '30px',width: '100%',textAlign: 'center'}}>STAFF HOURS
                </div>
            {!this.state.memberList && <Loaderview/>}
            <div className="scroll full_h_1 full_w">
                <div style={{display:'flex', flexWrap:'wrap', gap:"46px" , paddingLeft:"40px"}} id="staff-hours">
                {this.state.memberList && this.state.memberList.map((key, idx) => {
                    let infoRoster = resultFilter.filter(i => i.staff && key.user.id === i.staff.id);
                    let staffname =key.name
                        if(key.role === 'OWNER' || key.status === 'INVITING') return null;
                            return (
                                <div key={idx} className="col-sm-4 col-lg-4 staff-hours-card">
                                <div className="info-in">
                                    <div className="img_left" style={{marginTop:"-33px" , justifyContent:'space-between', padding:'0px 24px 0px 14px', display:'flex' , alignItems:"end"}}>
                                        <img alt="" className="ava-member"  src={key.user.avatar ? key.user.avatar : Config.avaDef}/>
                                        <div className='view-user-info'  onClick={() => this._goToDeTail(key.id)}>View</div>
                                     </div>
                                <div className="display_col justify-center full_w  text-view">
                                    <div className="staffname">{this._staffNameLength(staffname)}</div>
                                        <div className="barista">{key.title}</div>
                                        <div className="working"> Next: {(infoRoster && infoRoster.length > 0 && infoRoster[0].activities && infoRoster[0].activities.length > 0 && infoRoster[0].activities[0].timeStart !== (undefined) && infoRoster[0].activities[0].timeEnd !== (undefined)) ? ((infoRoster[0].activities[0].timeStart === 0 && infoRoster[0].activities[0].timeEnd === 0 ? 'OFF': moment(infoRoster[0].activities[0].time).calendar(null, formatDate) + ' ' + this.changeTimeToSecond(infoRoster[0].activities[0].timeStart) + ' - ' + this.changeTimeToSecond(infoRoster[0].activities[0].timeEnd))): "OFF"}
                                        </div>
                                        <div className="short-descrition">{key.des}</div>
                                </div>
                                </div>
                                    {key.contentImage && (
                                        <div className="content_image">
                                            <img src={key.contentImage} alt="#"/>
                                        </div>)}
                                </div>
                                    )})
                                    }
                </div>
                                <div style={{height:"80px"}}></div>
            </div>                  
            </div>
        </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(state => ({
        // checkLogin : state.main.checkLogin,
        teamList: state.team.teamList,
        memberList: state.team.memberList,
        // memberInfo: state.member.memberInfo
    }),
    (dispatch) => ({
        // userActions: bindActionCreators(userActions, dispatch),
        // companyActions: bindActionCreators(companyActions, dispatch),
        // mainActions: bindActionCreators(mainActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
        memberActions: bindActionCreators(memberActions, dispatch),
        rosterActions: bindActionCreators(rosterActions, dispatch)
    })
)(StaffHours);