
import React,{Component} from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';

//import action
import * as userActions from "../../redux/user/user_actions";
import * as companyActions from "../../redux/company/company_actions";
import * as mainActions from "../../redux/main/main_actions";
import * as teamActions from "../../redux/team/team_actions";
import * as eventActions from "../../redux/event/event_actions";
import * as shiftActions from "../../redux/shift/shift_actions";

import Header from '../header/header';

//import css
import '../register/register.css'
import './shift.css'
import '../my-team/my-team.css'
import '../my-team-detail/my-team-detail.css'
import '../libs/date-time-picker/date-time-picker.css';

//import component
import {Button, Image,Modal} from 'react-bootstrap';
import CForm from "../libs/cform/cform";
import moment from "moment";
import Config from "../../config";
import {browserHistory} from "react-router";
import ReactLoading from "react-loading";
import { CirclePicker } from 'react-color'

class Shift extends Component{

    constructor(props){
        super(props);
        this.state={
            idTeam: null,
            idColor: '#FFEC00',
            flagCreate:true,
            flagEdit:false,
            shiftListLoad:[],
            isLoading: false,
            isTimeError: false,
            fixedValue: 70,
            deleteshift:{}            
        };
        this.dataFilter = {
            limit: 1000,
            skip: 0,
        };
        this.dataFormDef = [
            {
                type: 'TEXT',
                key: "name",
                id: "name",
                placeholder:"Shift Name",
                def_value: '',
                // def_value:'TrangHoang',
                validator: 'isRequired',
                feedback: false,
                maxLength: 20
            },
            {
                type: 'TIME',
                key: "timeStart",
                id: "timeStart",
                class:"rc-time-picker-input",
                placeholder:"Start Time",
                validator: 'isRequired',
                label:'Start Time',
                def_value: '00:00',
                // def_value:moment().format('HH:mm'),
                feedback: false,
            },
            {
                type: 'TIME',
                key: "timeEnd",
                id: "timeEnd",
                class:"rc-time-picker-input",
                placeholder:"Finish Time",
                label:'Finish Time',
                def_value: '00:00',
                // def_value:moment().add(2,'hour').format('HH:mm'),
                validator: 'isRequired',
                feedback: false,
            }
        ];
        this.dataForm = [
            {
                type: 'TEXT',
                key: "name",
                id: "name",
                placeholder:"Shift Name",
                def_value: '',
                // def_value:'TrangHoang',
                validator: 'isRequired',
                feedback: false,
                maxLength: 20,
             
            },
            {
                type: 'TIME',
                key: "timeStart",
                id: "timeStart",
            
                class:"rc-time-picker-input",
                placeholder:"Start Time",
                validator: 'isRequired',
                label:'Start Time',
                def_value: '00:00',
                // def_value:moment().format('HH:mm'),
                feedback: false,
            },
            {
                type: 'TIME',
                key: "timeEnd",
                id: "timeEnd",
                class:"rc-time-picker-input",
                placeholder:"Finish Time",
                label:'Finish Time',
                def_value: '00:00',
                // def_value:moment().add(2,'hour').format('HH:mm'),
                validator: 'isRequired',
                feedback: false,
            }
        ];
        this.dataFormEdit = [
            {
                type: 'TEXT',
                key: "name",
                id: "name",
                placeholder:"Shift Name",
                def_value: '',
                // def_value:'TrangHoang',
                validator: 'isRequired',
                feedback: false,
                maxLength: 20
            },
            {
                type: 'TIME',
                key: "timeStart",
                id: "timeStart",
                class:"rc-time-picker-input",
                placeholder:"Start Time",
                validator: 'isRequired',
                label:'Start Time',
                def_value: '00:00',
                // def_value:moment().format('HH:mm'),
                feedback: false,
            },
            {
                type: 'TIME',
                key: "timeEnd",
                id: "timeEnd",
                class:"rc-time-picker-input",
                placeholder:"Finish Time",
                label:'Finish Time',
                def_value: '00:00',
                // def_value:moment().add(2,'hour').format('HH:mm'),
                validator: 'isRequired',
                feedback: false,
            }
        ];
    }

    componentDidMount() {
        this.init();
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillMount() {
        // window.removeEventListener('scroll', this.handleScroll);
    }

    _onLogout = () => {
        this.props.mainActions.checkOut((err, data) => {
            window.location.reload();
        });
    };

    handleScroll = () => {
        let scrollTop = window.pageYOffset;
        if(scrollTop > 5) {
            this.setState({
                fixedValue: 60
            });
        } else {
            this.setState({
                fixedValue: 70
            });
        }
    }


    init(){
        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
        let condition = {company: checkLogin.company.id};
        this.dataFilter.condition = JSON.stringify(condition);
        this.props.shiftActions.getListShift(this.dataFilter,(err, data)=> {
            
            if(err){
                this.setState({
                    shiftListLoad:[]
                });
            }
            else{
			data = data.filter(item => !(item.name === "CUSTOM"));
                this.setState({
                    shiftListLoad:data,
                    messageShift: '',
                    isTimeError: false
                });
            }
        });
    }

    // changeDropDownTeam = (data) => {
    //     this.setState({
    //         idTeam: data.id
    //     },()=>{
    //         // this._init();
    //         this.getEvent(data.id);
    //     });
    // };

    // getEvent = (id) => {
    //     // let begin = moment(date).startOf(type).toISOString();
    //     // let end = moment(date).startOf(type).toISOString();
    //     const cnt = {
    //         // "and" : [
    //             // {"time": {'>=': begin, '<=': end}},
    //             "team": id ? id : this.state.idTeam
    //         // ]
    //     };
    //
    //     this.dataFilter.condition = JSON.stringify(cnt);
    //     this.props.eventActions.getListEvent(this.dataFilter, (err, data)=>{
    //         if(err){
    //             Config.alertMess(err);
    //             this.setState({
    //                 listEvent: []
    //             })
    //         }
    //         else{
    //             this.setState({
    //                 listEvent: data
    //             })
    //         }
    //     })
    // };

    toggleCreateEvent = () => {
        this.setState({
            isCreateEvent: !this.state.isCreateEvent
        })
    };

    setDataListTeam = (data,id) => {
        this.setState({
            idTeam: this.state.idTeam ? this.state.idTeam : (id ? id : null)
        },()=>{
            this.init();
        });
    };

    changeColor = (color) => {
        this.setState({
            idColor: color.hex
        })
    };

    changeTimeToSecond = (time) => {
        let a = time.toString().split('.');
        let second = Math.round(parseFloat('0.' + (a[1] || 0) )* 60);
        second = second <= 9 ? '0'+ parseInt(second,10) : parseInt(second,10);
        a = a[0] + ':' + second;
        return a;
    };

    changeTimeToNumber = (time) => {
        let formatTime = ''
        console.log(time,typeof(time))
        if(typeof(time) === 'object'){
            formatTime = moment(time).format("hh:mm")
        }
        else{
        formatTime = time
        }
        console.log("=======>format time",formatTime)
        let a = formatTime.split(':');
        a = parseInt(a[0],10) + a[1]/60;
        return parseFloat(a);
    };    
    _removeShift = (shift) => {   
        
        this.setState ({deleteshift:shift});  
        this.setState({ isUpdateRemoveTeamConfirmed: true });
        this.setState({
            flagCreate: false,
            flagEdit: false,
        });
      };
    _confirmDelete = (shift) => {       
        this.props.shiftActions.removeShift({id: shift.id}, (err,data)=>{
            if(err){
                Config.alertMess(err);
            }
            else{
                this.init();
                setTimeout(() => {
                    this._handleClose();
                  }, 600);
            }
        }); 
        
    };
    _handleClose = () => {
        this.setState({ isUpdateRemoveTeamConfirmed: false });       
      };

    _addShift = () => {
        if(this.state.isLoading) return;
        this.setState({
            isLoading: true
        });
        let check = this.refs['CForm'].formValidate();
        console.log(check);
        let dt = this.refs['CForm'].formValue();
        console.log(dt);
        if(!check && this.state.idColor == '') {
            this.setState({
                messageShift: '* Please fill in Shift Name and set the start and end time for the shift.',
                isLoading: false,
                isTimeError: true
            });
            setTimeout(()=>{
                this.setState({messageShift: '', isLoading: false});
                this.isSubmit = false;
            },5000);
            return;
        }
        let params = {
            team: this.state.idTeam,
            name: dt.name.value,
            color: this.state.idColor,
            start: this.changeTimeToNumber(dt.timeStart.value),
            end: this.changeTimeToNumber(dt.timeEnd.value)
        };
        // console.log(params)
        // console.log(dt.timeStart)
        this.setState({
            flagCreate: false,
            flagEdit: false,
        });
        if(this.state.flagEdit){
           
            params.id = this.state.flagEdit;
            this.props.shiftActions.editShift(params,(err, data)=> {
                
                if(err){
                    this.setState({messageShift: err.message, flagCreate:true, flagEdit: false, idColor:'#FFEC00', isLoading: false});
                    setTimeout(()=>{
                        this.setState({messageShift: '', flagCreate:true, flagEdit: false, idColor:'#FFEC00', isLoading: false});
                        this.isSubmit = false;
                    },5000);
                }
                else if(data){
                    this.isSubmit = false;
                    this.setState({flagCreate:true, flagEdit: false, idColor:'#FFEC00', isLoading: false});
                    this.init();
                }
            });
        }else{
            this.props.shiftActions.addShift(params,(err, data)=> {
                
                if(err){
                    this.setState({messageShift: err.message, flagCreate:true, flagEdit: false, idColor:'#FFEC00', isLoading: false});
                    setTimeout(()=>{
                        this.setState({messageShift: '', flagCreate:true, flagEdit: false, idColor:'#FFEC00', isLoading: false});
                        this.isSubmit = false;
                    },5000);
                }
                else if(data){
                    // this.isSubmit = false;
                    this.setState({flagCreate:true, flagEdit: false, idColor:'#FFEC00', isLoading: false});
                    this.init();
                }
            });
        }

    };

    changeDropDownTeam = (data) => {
        this.setState({
            idTeam: data.id
        });
    };

    editShift = (data) => {
        
        this.setState({
            flagEdit: false
        },()=>{
            let start = this.changeTimeToSecond(data.start);
            let end = this.changeTimeToSecond(data.end);
            this.dataFormEdit[0].def_value = data.name;
            this.dataFormEdit[1].def_value = moment(start,'HH:mm').format('HH:mm');
            this.dataFormEdit[2].def_value = moment(end,'HH:mm').format('HH:mm');
            this.setState({
                idColor:data.color,
                flagEdit: data.id
            });
            console.log("start",start,"end",end,"this.dataFormEdit[0].def_value","this.dataFormEdit[1].def_value",this.dataFormEdit[1].def_value,"this.dataFormEdit[2].def_value",this.dataFormEdit[2].def_value);
            
        });
    };

    createStaffHours = () => {
        browserHistory.push('/staffhours');
    };

    _goToRoster = () => {
        browserHistory.push('/roster');
    };

    render(){
        // let {teamList} = this.props;
        let {shiftListLoad, isTimeError} = this.state;
        // let myTeam = [];
        // if(teamList && this.state.idTeam){
        //     myTeam = teamList.find(i=>{return (i.id === this.state.idTeam)});
        // }

        let login = localStorage.getItem('LOGIN');
        login = login ? JSON.parse(login) : null;
        
        return(
            <div className="full_w full_h">
                <Header isSearch={false} type={1} login={login} onLogout={this._onLogout}/>
                <div className="register calendar-view row my-team-detail my-team shift-view">
                    <div style={{position:'relative', minHeight:'100%'}} className="full_w full_h_1">
                        <div className="col-sm-12 display_col padding-0 full_h_1" style={{position:'relative'}}>
                            <div className="search-bar col-sm-12 shadow-1 display_row align-center sub-header-fixed" style={{top:this.state.fixedValue}}>
                                {/*<SearchBar isSearch={true}*/}
                                           {/*teamList={teamList}*/}
                                           {/*myTeam={myTeam}*/}
                                           {/*dataListTeam={this.setDataListTeam}*/}
                                           {/*changeDropDownTeam={this.changeDropDownTeam}/>*/}
                                <div className="search-view" style={{flexWrap:'wrap', justifyContent:'flex-end'}}>
                                    <div className="display_row align-center btn-new-event create-shift">
                                        <Image src={require('../../assets/images/roster/icon-create-shift-1.png')}
                                               className="icon-plus"/>
                                        Create Shift
                                    </div>
                                    <div className="display_row align-center btn-new-event"
                                         onClick={this.createStaffHours}>
                                        <Image src={require('../../assets/images/roster/icon-hours.png')}
                                               className="icon-plus"/>
                                        View Staff Hours
                                    </div>
                                </div>
                            </div>
                            <div className="member-view shift-form display_col">
                                <div className="back-to-page"  onClick={() => { browserHistory.goBack() }} style={{marginTop:"-9.7%"}}>
                                               <img alt="" src={require('../../assets/images/roster/icon-back-def.png')} style={{width:"12px" , height:"20px" , position:"fixed" , marginLeft:"-19px"}} />
                                                 <div className='back-text'>Back</div>
                                               </div>
                                <div className="col-sm-12 month-view display_row align-around scroll"
                                     style={{border:'none', alignItems:'flex-start', flexWrap:'wrap' , paddingTop:"5%"}}>
                                    <div className="col-sm-6" style={{border:'none', padding:'0 30px', textAlign:'center', marginTop:40}}>
                                        <div style={{width:'100%', maxWidth:270, position:'relative'}}>
                                            <div className="txt-condensed-extra-bold" style={{
                                                fontSize: 24,
                                                lineHeight: '30px',
                                                marginBottom: 40,
                                                width: '100%',
                                                textAlign: 'left'
                                            }}>CREATE SHIFT
                                            </div>
                                            {(this.state.flagCreate && !this.state.flagEdit) && <CForm isTimeError={isTimeError} data={this.dataForm} ref="CForm"
                                                   styleForm="form-create-shift"/>}
                                            {(!this.state.flagCreate && !this.state.flagEdit) && <CForm isTimeError={isTimeError} data={this.dataFormDef} ref="CForm"
                                                   styleForm="form-create-shift"/>}
                                            {this.state.flagEdit && <CForm data={this.dataFormEdit} ref="CForm"
                                                   styleForm="form-create-shift"/>}
                                            <div className={'shift-select-color'}
                                                 style={{fontSize: 16, textAlign: 'center'}}>Select shift color
                                            </div>
                                            <div className="display_row color-view valign-middle">
                                            {this.state.idColor && <CirclePicker onChangeComplete={this.changeColor} />}
                                            </div>
                                            {this.state.messageShift!=='' && (
                                                <div className="register_error" style={{width:'120%', marginLeft: '-10%', bottom:55, height:50, textAlign:'center',alignItems:'flex-end'}}>
                                                    {this.state.messageShift}
                                                </div>
                                            )}
                                            <Button className="menu-left-action btn-linear shadow btn-linear-invite"
                                                    onClick={this._addShift}>
                                                CREATE
                                            </Button>

                                            {this.state.isLoading &&
                                                <ReactLoading style={{color:'#000', width: 30, height: 30, position: 'absolute', bottom:10, left: 120}}
                                                              type={"spinningBubbles"}
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div className="col-sm-6" style={{border:'none', padding:'0 10px', textAlign:'center', marginTop:40}}>
                                        <div className="txt-condensed-extra-bold" style={{
                                            fontSize: 24,
                                            lineHeight: '30px',
                                            marginBottom: 60,
                                            width: '100%',
                                            textAlign: 'left'
                                        }}>EDIT SHIFT
                                        </div>
                                        <div style={{overflowY:'auto'}}>
                                        {shiftListLoad && shiftListLoad.map((key, idx)=>{
                                            
                                            const classColorBox = {backgroundColor:key.color, border:key.color !== 'transparent' ? '1px solid transparent' : '1px solid grey'};
                                            return(
                                                <div className="shift-item display_row shadow-1 align-left" key={idx+'shift-item'}>
                                                    <div className="color-box" style={classColorBox}>
                                                        {key.color === 'transparent' ? 'NO COLOR' : ''}
                                                    </div>
                                                    <div className="display_col hours-view">
                                                        <p style={{margin:2,maxWidth: 150}}>{key.name}</p>
                                                        <p style={{margin:2,maxWidth: 150}} className="hours-time">{moment(this.changeTimeToSecond(key.start),'HH:mm').format('HH:mm')} - {moment(this.changeTimeToSecond(key.end),'HH:mm').format('HH:mm')}</p>
                                                    </div>
                                                    <div className="display_col btn-view">
                                                        <p onClick={()=>this.editShift(key)}>Edit</p>
                                                        <p onClick={()=>this._removeShift(key)}>Remove</p>
                                                    </div>
                                                </div>
                                                
                                            )
                                        })}
                                        </div>
                                    </div>
                                    <Modal
                                        show={this.state.isUpdateRemoveTeamConfirmed}
                                        onHide={this.closeModal}
                                        id="popup-change-plan"
                                        className={"root-popup-change-plan remove-team-member"}
                                        style={{  maxWidth: "100%", margin: "auto" }}
                                    >
                                        
                                        <Modal.Body>
                                        <Image
                                            className="logo remove-member-logo"
                                            src={require("../../assets/images/register/icon-member.png")}
                                        />
                                        <div className="title">Remove Shift</div>
                                        <div className="title1 remove-admin">Do you want to remove this shift?</div>
                                        <div className="button_display_row" style={{ padding: "10px 0" }}>
                                            
                                            <Button
                                            className="login_btn_login btn-linear-orange shadow_popup"
                                            style={{ maxHeight: 50, margin: "0px 43px 0px 0px" , borderRadius:"25px"}}
                                            onClick={this._handleClose}
                                            >
                                            CANCEL
                                            </Button>
                                            <Button
                                            className="login_btn_login btn-linear shadow_popup"
                                            style={{ maxHeight: 50, margin: "0 5px" , borderRadius:"25px"}}
                                            onClick={() => this._confirmDelete(this.state.deleteshift)}
                                            >
                                            CONFIRM
                                            </Button>
                                        </div>
                                        </Modal.Body>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(state => ({
        checkLogin : state.main.checkLogin,
        teamList: state.team.teamList,
        memberList: state.team.memberList,
        eventList: state.team.eventList,
        shiftList: state.shift.shiftList
    }),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch),
        companyActions: bindActionCreators(companyActions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
        eventActions: bindActionCreators(eventActions, dispatch),
        shiftActions: bindActionCreators(shiftActions, dispatch),
    })
)(Shift);