
import React,{Component} from 'react';
import * as userActions from "../../redux/user/user_actions";
import * as companyActions from "../../redux/company/company_actions";
import * as mainActions from "../../redux/main/main_actions";
import * as teamActions from "../../redux/team/team_actions";
import '../register/register.css'
import './my-team.css'
import {browserHistory} from 'react-router';
import {Button, Image,Modal,Carousel} from 'react-bootstrap';
import Config from "../../config";

import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import ReactLoading from "react-loading";
import Loader from '../loader/loader';
import Header from '../header/header';
import Loaderview from '../roster/loaderview';

class MyTeam extends Component{

    constructor(props){
        super(props);
        this.state = {
            teamList: null,
            createTeam: false,
            deleteteammembers:{},
            hasRedirected: false,
           
        };
    }

    componentDidMount() {
        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;   
        if (checkLogin && (!checkLogin.company.logo || !checkLogin.user.avatar) && !this.state.hasRedirected) {
            this.setState({ hasRedirected: true }); 
            window.location.href = "/on-boarding-two"; 
        } else {
            this._init();
        }
    }

    _init = (cb) => {
        console.log("======>this.props",this.props)
        this.props.teamActions.getTeamList((error, data)=> {
            console.log("this.data",data)
            if(error){
                Config.alertMess(error);
                if(error.code === 'SYS016'){
                    Config.popup.show('INFO',[Config.alertMess(error)],null, ()=>{
                        localStorage.removeItem('LOGIN');
                        window.location.href = '/login';
                        return;
                    });
                    setTimeout(()=>{
                        window.location.href='/login';
                    },3000);
                }
                this.setState({
                    teamList:[]
                },()=>{
                    cb && cb();
                })
            }
            else{
                let dataTenp = [];
                let checkLogin = localStorage.getItem('LOGIN');
                checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
                for(let i in data){
                    let isStaff = data[i].members.staffs.filter(i=>i.userID === checkLogin.user.id);
                    if(isStaff && isStaff.length <= 0)
                        dataTenp.push(data[i]);
                }
                console.log("datatenp",dataTenp)
                this.setState({
                    teamList:dataTenp
                },()=>{
                    cb && cb();
                })
            }
        });
    };

    _onCreateTeam = () => {
        browserHistory.push('/create-team');
    };

    _goTeamDetail = (data) => {
        if(!data || !data.id) return;
        this.props.teamActions.saveTeam(data.id,()=>{
            browserHistory.push({
                pathname: '/team-detail',
                state: {id:data.id}
            });
        });
    };
    _removeTeam = (item) => {
        this.setState({deleteteammembers:item});
        this.setState({ isUpdateRemoveTeamConfirmed: true });
      };
    _ConfirmRemove = (item) => {
    this._removeTeams(item);
    };
    _removeTeams = (item, cb) => {       
            this.props.teamActions.removeTeam({id: item.id},()=>{
                this._init();
                setTimeout(() => {
                    this._handleClose();                    
                  }, 300);  
                window.location.reload();              
            });        
    };
    _handleClose = () => {
        this.setState({ isUpdateRemoveTeamConfirmed: false });        
      };

    _editTeam = (dataListTemp,cb) => {
        let {teamList} = this.state;
        let dataTemp = [];
        teamList.forEach((item, index)=>{
            if(item.name !== dataListTemp[index].name){
                dataTemp.push(dataListTemp[index]);
            }
        });

        if(dataTemp.length === 0){
            cb && cb();
        }
        else{
            dataTemp.forEach((item, index)=>{
                this.props.teamActions.editTeam({id: item.id, name:item.name},()=>{
                    if(index===dataTemp.length-1){
                        this._init(()=>{
                            cb && cb();
                        });
                    }
                });
            });
        }
    };

    _onLogout = () => {
        this.props.mainActions.checkOut((err, data) => {
            window.location.reload();
        });
    };

    render(){
        let {teamList} = this.state;

        let login = localStorage.getItem('LOGIN');
        login = login ? JSON.parse(login) : null;

        return(
            <div className="full_w full_h height-100vh">
                {!this.state.teamList && <Loaderview/>}
                <Header type={1} login={login} onLogout={this._onLogout} isSearch={false}/>
                <div className="register1 my-team row body_container my-team-container">
                    
                    {teamList &&
                        <ListTeam teamList={teamList}
                                  onCreateTeam={this._onCreateTeam}
                                  goTeamDetail={this._goTeamDetail}
                                  removeTeam={this._removeTeam}
                                  editTeam={this._editTeam}
                        />
                    }
                </div>
                <Modal
            show={this.state.isUpdateRemoveTeamConfirmed}
            onHide={this.closeModal}
            id="popup-change-plan"
            className={"root-popup-change-plan remove-team-member my-team-remove-pop-up"}
            style={{  maxWidth: "100%", margin: "auto" }}
          >
            
            <Modal.Body>
              <Image
                className="logo remove-member-logo "
                src={require("../../assets/images/register/icon-member.png")}
              />
              <div className="title">Remove Team</div>
              <div className="title1 remove-admin">Do you want to remove this team?</div>
              <div className="button_display_row" style={{ padding: "10px 0" }}>
                
                <Button
                  className="login_btn_login btn-linear-orange"
                  style={{ maxHeight: 50, margin: "0px 43px 0px 0px" , borderRadius:"25px" , boxShadow: 'none'}}
                  onClick={this._handleClose}
                >
                  CANCEL
                </Button>
                <Button
                  className="login_btn_login btn-linear shadow_popup"
                  style={{ maxHeight: 50, margin: "0 5px" , borderRadius:"25px"}}
                  onClick={() => this._ConfirmRemove(this.state.deleteteammembers)}
                >
                  CONFIRM
                </Button>
              </div>
            </Modal.Body>
          </Modal>
            </div>
        )
    }
}
class ListTeam extends Component{

    constructor(props){
        super(props);
        this.state={
            flagEdit: false,
            isLoading: false,
            isRedirectMember: false,
            isMouseOver: false,
            index: 0,    
            ActivePages : 0       
        };
    }

    componentDidMount() {
        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;   
        if(!checkLogin.company.logo || !checkLogin.user.avatar){          
            // this._openModel();
        }
        let {teamList} = this.props;
        if(teamList.length == 0){
          this.setState({isMouseOver: true});
        }      
    }

    _onCreateTeam = () => {
        if(this.props.onCreateTeam) this.props.onCreateTeam();
    };

    _goTeamDetail = (key) => {
        if(this.props.goTeamDetail) this.props.goTeamDetail(key);
    };

    _editTeam = (dataListTemp) => {
        this.setState({
            isLoading: true
        });
        if(this.state.flagEdit && this.props.editTeam){
            this.props.editTeam(dataListTemp,()=>{
                this.setState({
                    flagEdit: !this.state.flagEdit,
                    isLoading: false
                })
            });
        }
        else{
            this.setState({
                flagEdit: !this.state.flagEdit,
                isLoading: false
            })
        }
    };

    _removeTeam = (key) => {
        if(this.props.removeTeam){
            this.props.removeTeam(key,()=>{
                this.setState({
                    flagEdit: false
                })
            });
        }
    };

    _openModel = () =>{
        this.setState({ isRedirectMember: true });
    }

    _ConfirmRemove = () =>{
        window.location.href = "/my-account";
    }

    _openToolTip = () => {
        this.setState({ isMouseOver: true });
    }

    _closeToolTip = () => {
        this.setState({ isMouseOver: false });
    }
    
    handleSelect = (selectedIndex) => {
        this.setState({ activeIndex: selectedIndex });
    };
    handlePrev=() => {
        const newIndex = this.state.activeIndex - 1;
        if (newIndex >= 0) {
            this.setState({ activeIndex: newIndex });
        }
    };
    handleNext = () => {
        const newIndex = this.state.ActivePages + 1;
        const totalSlides = 2; // Assuming 3 slides, change this according to your total slides

        if (newIndex < totalSlides) {
            this.setState({ activeIndex: newIndex });
        }
        if(newIndex == totalSlides){
            this.setState({ isMouseOver: false });
        }
    };
   
    render(){
        let {teamList} = this.props;
        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;

        let login = localStorage.getItem('LOGIN');
        login = login ? JSON.parse(login) : null;

        
        let isOwner = false;
        if(teamList && checkLogin && checkLogin.company && checkLogin.userRole.slug!=undefined &&  (checkLogin.userRole.slug=='manager' || checkLogin.userRole.slug=='owner')) isOwner=true;
        let dataListTemp = JSON.parse(JSON.stringify(teamList));
        const slideContent = [
            "Ok first things first. Start creating your team and adding your staff. A team can be a store or a particular department within your company and will help you to organise and keep large groups of staff tidy. If you want everyone together then one team will do.",
            "Then, let's fill out the Welcome tool. This will introduce staff to their teammates, and you can customise it to inform staff about anything they should expect or useful information for their onboarding. You can add steps and documents of your choice.",];
           
            
        return(
          
            <div className="col-sm-12 padding-0 full_h_1">
                {this.state.isLoading && <div style={{position:"absolute",top:0 , left:280}}><Loaderview/></div>}
                <div className="txt-condensed-extra-bold"
                     style={{fontSize:25,letterSpacing:"1px",position:'relative',display:'flex',marginTop:'18px'}}>MY TEAMS

                    <Button className={"menu-left-action btn-edit-team "+ (this.state.flagEdit ? "btn-linear" : "btn-linear-orange")+" shadow_popup"}
                            onClick={() => this._editTeam(dataListTemp)}
                            style={{borderRadius:"25px"}}
                    >
                        {/* {this.state.isLoading &&
                            <div className="full_w full_h_loading display_row align-center justify-center view-loading">
                                <ReactLoading type={"spinningBubbles"} color={'#000'} height={30} width={30}/>
                            </div>
                        } */}
                        {(this.state.flagEdit) ? "SAVE" : "EDIT"}
                    </Button>
                          
                    {this.state.flagEdit &&
                        <Button className="menu-left-action btn-edit-team btn-linear-orange shadow_popup"
                                style={{right: 170 , borderRadius:"25px"}}
                                onClick={() => {
                                    this.setState({flagEdit: false})
                                }}>
                            CANCEL
                        </Button>
                    }
                </div>
                {/* {!teamList &&
                <div className="display_row align-center justify-center" style={{marginTop:100}}>
                    <ReactLoading type={"spinningBubbles"} color={'#31CCBC'} height={30} width={30}/>
                </div>
                } */}
                {teamList && teamList.length === 0 &&
                    <p className="note-register text-center"
                       style={{margin:'0', fontSize: 21, marginTop:30}}>
                        You have not added a team yet. Start below!
                    </p>
                }
                <div className="team-view display_row scroll full_h_1" style={{paddingBottom:100}}>

                    {(isOwner && (!teamList || (teamList && teamList.length<10))) && (
                        <div className="display_col team-group animation-show"
                             style={{cursor:'pointer'}}
                             onClick={this._onCreateTeam}>
                            <Image src={require('../../assets/images/my-team/bg-create-team.png')}
                                   className="ava-team"/>
                            <div className="team-name">
                                Add team
                                <label className="icon-plus-team">+</label>
                            </div>
                        </div>
                    )}

                     {/* **************************Tooltip-images************************* */}

                    {teamList && teamList.length === 0 && (
                    <div className="tool-tip" 
                    style={{left: this.state.activeIndex === 1 ? '130px' : '460px', top :this.state.activeIndex === 1 ? "582px" : "323px"}}
                    >
                    <Image
                        src={require('../../assets/images/tooltip/tool-pointer.png')}
                        onMouseOver={this._openToolTip}
                        //onMouseOut={this._closeToolTip}
                        className="tool-pointer"
                    />
                    <div>                        
                           <Modal
                            show={this.state.isMouseOver}
                            onHide={this._closeToolTip}
                            className="tip-modal welcome_hint"
                            >
                            <Modal.Body>                                  
                             <div className='col tooltip-body'>                                                                                        
                                 <div className='row tooltip-header'>
                                    <div>
                                 <Image
                                    className="tooltip-img"
                                    src={require("../../assets/images/tooltip/laptop.png")}
                                    /> 
                                    </div>
                                    <div>
                                 <p>Build Your Team And Welcome Tool</p>
                                 </div> 
                                 <div className='team-logo'>
                                 <Image
                                    className="team-icon"
                                    src={require("../../assets/images/tooltip/logo-avatar.png")}
                                    /> 
                                 </div>                                  
                                 </div>
                                 <div className='row tooltip-bottom'>
                                <div className='row' style={{display:'block'}}>
                                <div className="col tooltip-bottom_row">
                                    <Image className="header-avatar"
                                           src={require("../../assets/images/tooltip/user-avatar.png")}
                                    />
                                    <div className="col tooltip-bottom-name">
                                            <p>Niamh</p>                                      
                                    </div>
                                 </div>

                                 <Carousel
                                    activeIndex={this.state.activeIndex} onSelect={this.handleSelect}                                                                 
                                 >  
                                  {slideContent.map((content, index) => (
                                  <Carousel.Item key={index}>                                                    
                                    <div className='col tooltip-bottom-cont'>                         
                                        <p>{content}</p>                                    
                                    </div>                                 
                                 </Carousel.Item>  
                                  ))} 
                                                            
                                 </Carousel>  
                                                     
                                   </div>                                
                                    <div className='change-page'>
                                         <button onClick={()=>{this.handlePrev()}}>PREV</button>
                                         <button onClick={()=>{this.handleNext()}}>NEXT</button>
                                    </div> 
                                   </div>
                                </div>
                            </Modal.Body>
                            </Modal>
                       </div>
                    </div>
                    )} 


                    {teamList && teamList.length > 0 && teamList.map((key, idx) => {
                    let logo = null;
                    if (key.members && key.members.admins && key.members.admins.length > 0) {
                    for (let admin in key.members.admins) {
                    if (key.members.admins[admin].userAvatar) {
                    logo = key.members.admins[admin].userAvatar;
                    break;
                      }
                     }
                    }
                    console.log("checkLogin company logo", checkLogin, logo);
                    logo = logo ? logo : (checkLogin && checkLogin.company && checkLogin.company.logo) || null; 
                        return(
                            <div key={'team-list-'+idx} className="display_col team-group animation-show" style={{cursor:'pointer', position:'relative'}}>
                                <Image src={logo} className="ava-team"  onClick={()=>{this._goTeamDetail(key)}}/>
                                {isOwner && this.state.flagEdit && <Image src={require('../../assets/images/button_team_delete.png')}
                                       className="icon-close icon-remove"
                                       style={{right: 7, top:10}}
                                       onClick={()=>this._removeTeam(key)}
                                />}
                                <div className="display_row justify-center" style={{marginBottom:5}}>
                                    {key.members.admins.map((keyAdmin, idxAdmin)=>{
                                        if(!keyAdmin.userAvatar) return null;
                                        return(
                                            <img key={"img-admin-"+idxAdmin} className="img-admin"
                                                 src={keyAdmin.userAvatar}/>
                                        )})
                                    }
                                </div>
                                {!this.state.flagEdit && <div className="team-name">
                                    {key.name.length > 14 ? `${key.name.substring(0, 14)}..` : key.name}
                                    </div>}
                                {this.state.flagEdit && <input className="input-team-name"
                                                               maxLength={25}
                                                               defaultValue={key.name}
                                                               onChange={(vl)=>{dataListTemp[idx].name=vl.target.value}}
                                />}
                            </div>
                        )
                    })}
                </div>
                <div>
                <Modal
                    show={this.state.isRedirectMember}
                   // onHide={this.closeModal}
                    className="fix-modal remove-user"
                    >
                    <Modal.Body>            
                        <Image
                        className="logo"
                        src={require("../../assets/images/icon-member.png")}
                        />                          
                        <div className="header-txt">Welcome to your dashboard</div>        
                        <div className="content" style={{ margin: "40px 70px 30px " }}>
                        To continue, we recommend you add your profile photo and your company logo so everyone can see a more completed account when they log in for the first time!             
                        </div>
                        <Button
                        className="login_btn_login btn-linear shadow"
                        style={{ maxHeight: 50, margin: "0 5px" }}
                        onClick={() => this._ConfirmRemove()}
                        >
                        NEXT
                        </Button>
                    </Modal.Body>
                    </Modal>
                </div>
            </div>

        )
    }
}
export default connect(state => ({
        checkLogin : state.main.checkLogin
    }),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch),
        companyActions: bindActionCreators(companyActions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
    })
)(MyTeam);